<template>
  <div class="demo12">
    <div class="top">
      <div v-show="value3 === '1'">
        <span>模型：</span>
        <el-select
          v-model="value1"
          :disabled="loading"
          popper-class="selectcss"
          placeholder="请选择"
        >
          <el-option
            v-for="item in options1"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div v-show="value3 === '1'">
        <span>图片比例： </span>
        <el-select
          v-model="value2"
          popper-class="selectcss"
          :disabled="loading"
          placeholder="请选择"
        >
          <el-option
            v-for="item in options2"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div>
        <span>切换源： </span>
        <el-select
          v-model="value3"
          popper-class="selectcss"
          :disabled="loading"
          placeholder="请选择"
        >
          <el-option
            v-for="item in options3"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="btm">
      <el-input
        class="ipt"
        type="textarea"
        v-model="text"
        placeholder="输入文字生成图片"
      ></el-input>
      <el-button type="primary" @click="handleAddImage">生成</el-button>
    </div>
    <div
      class="imgdiv"
      v-loading="loading"
      element-loading-text="图片生成中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <img :src="imgurl" alt="" />
    </div>
  </div>
</template>

<script>
import { wordToPicture, createImage } from "@/api";
import axios from "axios";
export default {
  data() {
    return {
      loading: false,
      text: "",
      imgurl: "",
      value1: "TongYongJiChu",
      options1: [
        {
          label: "通用基础",
          value: "TongYongJiChu",
        },
        {
          label: "通用写实2",
          value: "TongYongXieShi2",
        },
        {
          label: "光影增强",
          value: "GuangYingZengQiang",
        },
        {
          label: "概念场景",
          value: "GaiNianChangJing",
        },
        {
          label: "动漫风格",
          value: "DongManFengGe",
        },
      ],
      value2: "1:1",
      options2: [
        {
          label: "1:1",
          value: "1:1",
        },
        {
          label: "3:4",
          value: "3:4",
        },
        {
          label: "4:3",
          value: "4:3",
        },
      ],
      value3: "1",
      options3: [
        {
          label: "Tiamat",
          value: "1",
        },
        {
          label: "chatgpt",
          value: "2",
        },
      ],
    };
  },
  mounted() {},
  methods: {
    handleAddImage() {
      if (!this.text) {
        this.$message.error("请输入文字");
        return;
      }
      if (this.loading) {
        this.$message.warning("生成中请稍后");
        return;
      }
      this.loading = true;
      this.imgurl = "";
      let obj = null;
      if (this.value3 === "1") {
        obj = wordToPicture({
          abstractModel: this.value1,
          aspectRatio: this.value2,
          textPrompt: this.text,
        });
      } else {
        obj = createImage({
          prompt: this.text,
        });
      }

      obj
        .then((res) => {
          if (this.value3 === "1") {
            if (res.images.length) {
              this.imgurl = `data:image/png;base64,${res.images[0]}`;
            }
          } else {
            let data = JSON.parse(res.dataObj);
            this.imgurl = data[0].url;
          }
        })
        .catch((err) => {})
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.demo12 {
  width: 100vw;
  height: 100vh;
  .top {
    width: 100%;
    height: 50px;
    display: flex;
    line-height: 50px;
    span {
      display: inline-block;
      width: 95px;
    }
    .selectcss {
      height: 100%;
    }
  }
  .btm {
    width: 900px;
    display: flex;
    .el-button {
      margin-left: 10px;
    }
  }
  .imgdiv {
    width: 500px;
    min-height: 500px;
    margin-top: 10px;
    img {
      width: 100%;
    }
  }
}
</style>
